// FeedbackForm.js
import React, { useState, useMemo } from 'react';
import StarRating from './StarRating';
import { allCountryCodes } from './countryCodes.js'

// FeedbackForm Component
const FeedbackForm = ({ rating, setRating, onSubmit, isLoading }) => {

  const initialFormData = {
    phoneNumber: '',
    countryCode: '+1', // Default country code
    name: '',
    email: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [filteredCountryCodes, setFilteredCountryCodes] = useState([]);
   // Example country codes
  const [feedback, setFeedback] = useState('');
  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      return { isValid: false, message: 'Please enter a valid email address.' };
    }
    return { isValid: true, message: '' };
  };

  const validatePhoneNumber = (phoneNumber) => {
    const digitPattern = /^\d+$/; // Pattern to check if all characters are digits
    const validLengthPattern = /^\d{10,15}$/; // Pattern to check if the number is between 10 and 15 digits

    if (phoneNumber.length < 10) {
      return { isValid: false, message: 'Phone number is too short. It must be at least 10 digits.' };
    }

    if (!digitPattern.test(phoneNumber)) {
      return { isValid: false, message: 'Phone number should only contain digits' };
    }

    if (!validLengthPattern.test(phoneNumber)) {
      return { isValid: false, message: 'Phone number must be between 10 and 15 digits.' };
    }

    return { isValid: true, message: '' }; // Return valid if both conditions pass
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if name is empty
    if (!formData.name.trim()) {
      newErrors.name = 'Name cannot be empty';
    }

    // Check if email is valid
    const emailValidation = validateEmail(formData.email);
    if (!emailValidation.isValid) {
      newErrors.email = emailValidation.message;
    }

    // Check if phone number is valid (optional)
    if (formData.phoneNumber.trim()) {
      const phoneValidation = validatePhoneNumber(formData.phoneNumber);
      if (!phoneValidation.isValid) {
        newErrors.phoneNumber = phoneValidation.message;
      }
    }

    setErrors(newErrors);

    // If there are no errors, the form is valid
    return Object.keys(newErrors).length === 0;
  };

  // Handle combined form and rating submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }
    setErrors({});
    
    // Combine form data and rating into one submission object
    const submissionData = {
      ...formData 
    };
    onSubmit(rating, feedback, submissionData);
    setFormData(initialFormData)
    setFeedback('');
  
  };

  const handleCountryCodeInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, countryCode: value });

    // Filter the country codes based on input
    if (value) {
      const filtered = allCountryCodes.filter((country) =>
        country.name.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredCountryCodes(filtered);
    } else {
      setFilteredCountryCodes([]);
    }
  };

  const handleCountryCodeSelect = (code) => {
    setFormData({ ...formData, countryCode: code });
    setFilteredCountryCodes([]); // Clear the suggestions after selection
  };

  const LoadingSpinner = useMemo(
    () => () => (
      <svg
        className="animate-spin h-5 w-5 text-purple-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    ),
    []
  );

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 md:p-8 my-4 sm:my-6 md:my-8">
      <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-[#7128d7] mb-4">
        How did the call go?
      </h2>

      {/* Star Rating Component */}
      <StarRating rating={rating} setRating={setRating} feedback={feedback} setFeedback={setFeedback} />

      {/* Form for user details */}
      <form onSubmit={handleSubmit} className="flex flex-col items-center w-full mt-4">
        <div className="mb-4 w-full max-w-lg">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Enter your name"
            className="py-2 px-4 border rounded-lg w-full"
          />
          {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
        </div>

        <div className="mb-4 w-full max-w-lg">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Enter your email"
            className="py-2 px-4 border rounded-lg w-full"
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        <div className="mb-4 w-full max-w-lg">
          <div className="flex w-full relative">
            <input
              type="text"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleCountryCodeInputChange}
              placeholder="Enter country code or country"
              className="py-2 px-4 border rounded-l-lg bg-gray-100 w-1/4"
            />

            {filteredCountryCodes.length > 0 && (
              <ul className="absolute top-full left-0 w-1/4 bg-white border mt-1 z-10 max-h-40 overflow-y-auto">
                {filteredCountryCodes.map((country) => (
                  <li
                    key={country.code}
                    className="cursor-pointer py-2 px-4 hover:bg-gray-100"
                    onClick={() => handleCountryCodeSelect(country.code)}
                  >
                    {country.name} ({country.code})
                  </li>
                ))}
              </ul>
            )}

            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder="Enter your phone"
              className="py-2 px-4 border rounded-r-lg w-3/4"
            />
          </div>
          {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>}
        </div>

        <button
          type="submit"
          className={`py-2 px-4 bg-purple-900 text-white font-semibold rounded-lg shadow-md w-full max-w-lg mx-auto
                flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 text-sm sm:text-base`}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <LoadingSpinner />
              <span className="ml-2">Submitting...</span>
            </>
          ) : (
            <span>Submit Feedback</span>
          )}
        </button>
      </form>
    </div>
  );
};

export default FeedbackForm;
