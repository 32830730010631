import React, { useState, useMemo } from 'react';

const StarRating = ({ rating, setRating, onSubmit, feedback, setFeedback }) => {
    // const [feedback, setFeedback] = useState('');
    const stars = [1, 2, 3, 4, 5];
    
    const placeholderText = useMemo(() => {
        switch(rating) {
            case 1:
                return "We're sorry to hear that. Could you tell us what went wrong?";
            case 2:
                return "Thank you for your feedback. How can we improve?";
            case 3:
                return "Thanks for your rating. What could we do better?";
            case 4:
                return "Great! What did you like about your experience?";
            case 5:
                return "Excellent! We'd love to hear more about your great experience.";
            default:
                return "Please provide any additional feedback...";
        }
    }, [rating]);

    // const handleSubmit = () => {
    //     onSubmit(rating, feedback);
    //     setFeedback(''); // Clear feedback after submission
    // };

    return (

        <div className="space-y-4 p-4">
            <div className="flex justify-center space-x-2 flex-wrap">
                {stars.map((star) => (
                    <button
                        key={star}
                        onClick={() => setRating(star)}
                        className="focus:outline-none"
                    >
                        <svg
                            className={`w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 ${
                                star <= rating ? 'text-yellow-400' : 'text-gray-300'
                            }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                    </button>
                ))}
            </div>

            {rating > 0 && (
                <div className="mt-4 space-y-4">
            <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder={placeholderText}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#7128d7] focus:border-transparent resize-none"
                rows="4"
            ></textarea>
                    {/* <button
                        onClick={handleSubmit}
                        className="w-full py-2 px-4 bg-[#7128d7] text-white font-semibold rounded-lg shadow-md hover:bg-[#5f22b5] focus:outline-none focus:ring-2 focus:ring-[#7128d7] focus:ring-opacity-75"
                    >
                        Submit Feedback
                    </button> */}
                </div>
            )}
        </div>

    );
};

export default StarRating;